
import { defineComponent, PropType, reactive, toRefs, ref, h, watch } from 'vue'
import pagaTable from '@/components/pagination-table/index'
import { TransformCellTextProps } from 'ant-design-vue/lib/table/interface'
import formSearch from '@/components/page-search/form'
import {
  userInfoPage,
  departmentTree,
  updatePassword,
  userToDepartment_post
} from '@/api/system'
import popupWindow from './popupNew.vue'
import { userRole } from '@/api/system'
import { notification } from 'ant-design-vue'
const purchaseColumns = [
  {
    title: '序号',
    width: 60,
    dataIndex: 'index',
    customRender: (row: TransformCellTextProps) => row.index + 1
  },
  {
    title: '用户姓名',
    dataIndex: 'realName'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    slots: { customRender: 'operation' }
  }
]
export default defineComponent({
  name: 'questionType',
  components: {
    pagaTable,
    formSearch,
    popupWindow
  },
  setup(props: any) {
    let checkAge1 = async (value: any) => {
      const passwordreg =
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,20}/
      if (!value) {
        return Promise.reject('请输入密码')
      } else {
        if (!passwordreg.test(value)) {
          // return true
          return Promise.reject(
            '密码为8-20位,且同时包含数字、大写字母、小写字母、特殊符号'
          )
        } else {
          return true
        }
      }
    }
    let checkAge2 = async (value: number) => {
      if (!value) {
        return Promise.reject('请输入密码')
      } else {
        if (formState.password1 != formState.password2) {
          return Promise.reject('密码不一致')
        } else {
          return true
        }
      }
    }
    const data = reactive({
      rules: {
        password1: [{ validator: checkAge1, trigger: 'change' }],
        password2: [{ validator: checkAge2, trigger: 'change' }]
      },
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24
          }
        }
      },
      formConfig: [
        {
          colProps: {
            span: 8
          },
          formItemProps: {
            label: '姓名：'
          },
          comConfig: {
            tag: 'input', // input, select, date-picker
            key: 'realName', // 即是插槽也是字段model key
            props: {
              placeholder: '请输入'
            }
          }
        }
      ]
    })
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys
    }
    const table = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
          isAdmin: false,
          ...formSearch.value.getQuery(),
          departmentId: departmentId.value ? departmentId.value : ''
        }
        userInfoPage({ ...params_api }).then((res: any) => {
          const resList = res.data.map((row: any) => {
            return { ...row, checked: row.roleName === '安全员' ? true : false }
          })
          const api = { ...res, data: resList }
          reslove(api)
        })
      })
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      // console.log(pagination, filter, sorter, currentDataSource)
    }
    const formSearch = ref()
    const popupWindow = ref()
    const Title = ref()
    const updatedTime = ref([])
    const handleSearch = (formState: any) => {
      table.value.refresh()
    }
    const handleClick = (text: string, row: any) => {
      Title.value = '编辑'
      popupWindow.value.showModal(row.userId)
    }
    const selectedKeys = ref<string[]>([])
    const departmentId = ref()
    watch(selectedKeys, () => {
      departmentId.value = selectedKeys.value
      handleSearch({})
    })
    const value = ref<string>('')

    const onSearch = () => {
      // console.log('value', value.value)
    }
    const treeData = ref([])
    departmentTree().then((res) => {
      treeData.value = res.data
    })
    const replaceFields = {
      children: 'children',
      key: 'departmentId',
      title: 'departmentName'
    }
    let formState = reactive({
      password1: '',
      password2: ''
    })
    const visible = ref(false)
    const ChangePassword = (record: { userId: any }) => {
      userId.value = record.userId
      visible.value = true
    }
    const formRef = ref()
    const userId = ref()
    const handleOk = () => {
      formRef.value.validate().then(() => {
        updatePassword({
          password: formState.password1,
          userId: userId.value
        }).then((res) => {
          visible.value = false
          formState = {
            password1: '',
            password2: ''
          }
        })
      })
    }
    const departmentvisible = ref(false)
    const responsibleDepartment = ref(null)
    const departmentReplaceFields = ref({
      title: 'departmentName',
      value: 'departmentId',
      key: 'departmentId',
      children: 'children'
    })
    const department = (record: { userId: any; department: any }) => {
      userId.value = record.userId
      if (record.department) {
        responsibleDepartment.value = record.department
      } else {
        responsibleDepartment.value = null
      }
      departmentvisible.value = true
    }
    const departmentHandleOk = () => {
      if (responsibleDepartment.value) {
        userToDepartment_post({
          userId: userId.value,
          departmentId: responsibleDepartment.value
        }).then((res) => {
          departmentvisible.value = false
          table.value.refresh()
        })
      } else {
        notification.error({
          message: '提示',
          description: '请选择部门'
        })
      }
    }
    const checked = ref<boolean>(false)
    const clickChange = (row: any) => {
      if (row.checked) {
        const roleIds = ['615a461453a28c4f736013c26cad5abc']
        userRole({ roleIds: roleIds, userId: row.userId }).then((res) => {
          handleSearch({})
        })
      } else {
        const roleIds: any = []
        userRole({ roleIds: roleIds, userId: row.userId }).then((res) => {
          handleSearch({})
        })
      }
    }
    return {
      clickChange,
      checked,
      notification,
      departmentHandleOk,
      responsibleDepartment,
      departmentReplaceFields,
      departmentvisible,
      department,
      formRef,
      formState,
      handleOk,
      visible,
      ChangePassword,
      replaceFields,
      handleClick,
      value,
      onSearch,
      treeData,
      selectedKeys,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      formSearch,
      popupWindow,
      Title,
      handleSearch,
      updatedTime
    }
  }
})
